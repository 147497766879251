<template>
  <div class="wrap">
    <div class="tag_box">
      <el-tag v-if="myCourseShow" class="tag_item" closable type="info" effect="plain" @click="toMyCourse"
        @close="myCourseShow = false">我的课程</el-tag>
      <i v-if="myCourseShow" class="iconfont icon-xiangyou" />
      <el-tag v-if="currentTag" class="tag_item current_tag" closable effect="plain" @close="() => {
          currentTag = false
          toMyCourse()
        }
        ">{{ className }}</el-tag>
    </div>
    <!-- <div class="year_box">
      <div
        @click="changeYear(item)"
        class="year_item"
        :class="year == item.year ? 'on' : ''"
        v-for="item in yearKc"
        :key="item.year"
      >
        {{ item.year }}年
      </div>
      <div class="clear_float"></div>
    </div> -->
    <div v-if="smallList && smallList.length > 0" class="xbkmain">
      <div class="xbk_til">小班课</div>
      <div class="xbk_detail ellipsis">
        <div class="xbk_detail_top ellipsis">{{ smallList[0].name }}</div>
        <div class="xbk_detail_bot">
          <div class="icondk">
            <img src="@/assets/img/homeSeventh/xbk2.png" alt="">
          </div> 到课率：{{ smallList[0].arriveRate ? smallList[0].arriveRate : 0 }}%
        </div>
      </div>
      <div class="xbk_btn" @click="toCourses(smallList[0])">观看课程</div>
    </div>
    <div class="content_box">
      <div v-for="item in courseNameList" :key="item.kcId" class="content_item">
        <div class="name_box">
          <span class="name">{{ item.kcName }}</span>
          <div v-for="items in item.courseTypes" :key="items.id" class="tag">
            <span v-if="items == 1">直播课</span>
            <span v-if="items == 2">录播课</span>
            <span v-if="items == 5">面授课</span>
          </div>
        </div>
        <div class="progress_bar">
          <div class="schedule" :style="`width: ${item.studyRate}%;`" />
        </div>
        <div class="btn_box">
          <div class="schedule_box">
            <img class="img" src="@/assets/img/homeSeventh/sandClock.png" alt="">
            <span class="text">已学：{{ item.studyRate }}%</span>
          </div>
          <div class="btn" @click="toCourse(item)">观看课程</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from '@/api/cookies'
import { selectRateByClassIdYearStudentIdPC } from '@/api/mykecheng'
import { Know } from '@/api/know'
const know = new Know()
export default {
  data() {
    return {
      userInfo: null,
      id: null, // 套餐ID
      yearKc: [],
      className: '',
      myCourseShow: true, // 我的课程标签
      currentTag: true, // 当前页标签
      year: '',
      courseNameList: [],
      smallList: []
    }
  },
  created() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.className = this.$route.query.className
    this.kcbClassId = this.$route.query.kcbClassId
    this.userInfo = getInfo()
    this.getList()
    this.getPrivateClassList()
  },
  methods: {
    // getYear () {
    //   know
    //     .classesTableKsTime(
    //       this.type == 16 ? this.kcbClassId : this.id,
    //       this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
    //     )
    //     .then((res) => {
   
    //       if (res.code == 0) {
    //         this.yearKc = res.data.yearKc
    //         // this.year = res.data.yearKc[0].year
    //         this.getList()
    //         // this.currentYear = res.data.yearKc[0].year;
    //         // this.courseName = res.data.yearKc[0].yearKcNames;
    //       }
    //     })
    // },
    async getPrivateClassList() {
      await know
        .getPrivateClassList(
          this.type == 1 ? this.id : this.kcbClassId,
          this.userInfo.id
        )
        .then((res) => {
          if (res.code != 500) {
            this.smallList = res.data
          }
        })
    },
    getList() {
      const data = {
        classesId: this.id,
        // year: this.year,
        studentId: this.userInfo.id,
        type: this.type
      }
      selectRateByClassIdYearStudentIdPC(data).then((res) => {
        this.courseNameList = res.msg
      })
    },
    toMyCourse() {
      this.$router.push({
        path: `/all/mycurriculum`
      })
    },
    toCourse(item) {
      this.$router.push({
        path: `/all/courseLearningRate?kcbClassId=${this.kcbClassId}&type=${this.type}&id=${this.id}&kcId=${item.kcId}&className=${this.className}&kcName=${item.kcName}&itype=${item.type}`,
        query: {
          courseTypes: item.courseTypes
        }
      })
    },
    toCourses(item) {

      this.$router.push({
        path: `/all/courseLearningRateXbk?kcbClassId=${this.kcbClassId}&type=${this.type}&id=${this.id}&kcId=${item.kcId}&className=${this.className}&kcName=${item.kcName}&itype=${item.type}`,
        query: {
          courseTypes: item.courseTypes
        }
      })
    },
    changeYear(item) {
      this.year = item.year
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background-color: #ffffff;
  padding: 24px 32px;
  border-radius: 0 0 8px 8px;

  .tag_box {
    .tag_item {
      cursor: pointer;
    }

    .current_tag {
      color: #ff5e51;
      border-color: #ff5e51;

      ::v-deep>.el-tag__close {
        color: #ff5e51;
      }
    }

    .icon-xiangyou {
      color: #aaaaaa;
      margin: 0 12px;
      font-size: 12px;
    }
  }

  .year_box {
    padding: 4px;
    height: 38px;
    margin-top: 24px;
    min-width: 140px;
    background-color: #f5f7f9;
    display: inline-block;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 20px;

    .year_item {
      width: 132px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      float: left;
      cursor: pointer;
    }

    .on {
      background-color: #ffffff;
      color: #ff5e51;
      border-radius: 6px;
      transition: 200ms;
      font-weight: 600;
    }

    .clear_float {
      clear: both;
    }
  }

  .content_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .content_item {
      padding: 16px;
      width: 398px;
      height: 127px;
      border-radius: 8px;
      background: linear-gradient(180deg, #f1f6ff 0%, #ffffff 100%);
      box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.34);
      border: 1px solid rgba(181, 201, 255, 0.3);
      margin-bottom: 20px;

      .name_box {
        display: flex;
        margin-bottom: 20px;

        .name {
          max-width: 300px;
          margin-right: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333333;
        }

        .tag {
          // width: 45px;
          height: 21px;
          color: #ff7b1c;
          font-size: 11px;
          border-radius: 4px;
          text-align: center;
          line-height: 21px;
          flex-shrink: 0;

          span {
            width: 45px;
            background-color: #fff2e9;
            margin-right: 5px;
            padding: 2px;
          }
        }
      }

      .progress_bar {
        width: 347px;
        height: 10px;
        background-color: #efefef;
        border-radius: 5px;
        margin-bottom: 14px;

        .schedule {
          background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
          height: 100%;
          width: 0;
          border-radius: 5px;
        }
      }

      .btn_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .schedule_box {
          display: flex;
          align-items: center;

          .img {
            width: 12px;
            height: 14px;
            margin-right: 5px;
          }

          .text {
            color: #777777;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .btn {
          width: 88px;
          height: 30px;
          border-radius: 4px;
          line-height: 30px;
          font-size: 14px;
          color: #ff5e51;
          text-align: center;
          border: 1px solid #ff5e51;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .el-tag--plain {
  color: #4a6af0;
  border-color: #4a6af0;
}

::v-deep .el-tag {
  height: 28px;
  line-height: 28px;
}

::v-deep .el-tag--info {
  border-color: #e9e9eb;
  color: #909399;
}

.iconfont {
  font-size: 14px;
}

.xbkmain {
  width: 100%;
  height: 90px;
  background-image: url("~@/assets/img/homeSeventh/xbk1.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 17px 20px 17px 32px;
  margin: 20px auto;

  .xbk_til {
    width: 92px;
    height: 22px;
    font-size: 24px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    flex-shrink: 0;
    border-right: 1px solid #ffffff;
    line-height: 20px;
  }

  .xbk_detail {
    width: 580px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;

    .xbk_detail_top {
      width: 432px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }

    .xbk_detail_bot {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;

      .icondk {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  .xbk_btn {
    width: 88px;
    height: 30px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #ffffff;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
  }
}</style>
